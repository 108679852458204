
/* 
  
  Custom CSS goes here
  
*/
.navbar {
  background: #fff;
  position: fixed;
  top: 0px;
 
}

.floating-button {
  display: none;
}
.bg-secondary-light {
  background: #fdf8f2!important;
}
.navbar-main {
  margin-top: 54px !important;
  background-color: inherit;
  background: transparent;
}

.scrolling-nav {
  background: #fff !important;
  box-shadow:  0px 1px 5px #ddd!important;
}
.footer-result {
  display: none;
}
.page-header {
 /*   height: 100vh !important;*/
  }
  .Calculator .form-control {
    border-radius: 8px !important;
    border: 1px solid #ccc;
    width: 100% !important;
  }
  .rates-row {
   border-radius: 12px;
   padding: 20px;
   background: #f9f9f9 !important;
  
  }
  .text-secondary {
    color: #F0B884 !important
  }
 
  .Calculator label {
    font-weight: 500; 
  }
  .Calculator .col-3 {
    margin-bottom: 30px !important;
    padding: 6px !important
  }
  
  .Calculator form .col-6, .Calculator form .col-12 {
    float: left !important;
    margin-bottom: 20px !important;
    padding: 0px !important;
    padding-right: 5px !important;
  }
  .px-vr-30 {
      padding-right: 30px !important;
      padding-left: 30px !important;
    }
    .text-highlight {
      display: inline;
      color: #EEF7FF;
      padding: 0px 16px;
      border-radius: 10px;
    }
    
    .error:before {
      content: attr(data-text);
      position: absolute;
      left: -2px;
      text-shadow: 1px 0 #0061f2;
      top: 0;
      color: #323f52;
      background: #fff;
      overflow: hidden;
      clip: rect(0, 900px, 0, 0);
      animation: noise-anim-2 3s infinite linear alternate-reverse;
    }
    .navbar-nav {
      padding-bottom: 8px !important;
      padding-top: 8px !important;
      
    }
    .whyus {
            margin: auto;
  
    }
    
    .navbar-marketing .btn {
      font-size: 14px !important;
      font-family: 'Poppins' !important;
      letter-spacing: inherit !important;
      text-transform: none !important;
    }
    .whyus .card {
      border-radius: 10px;
      text-align: left;
      box-shadow: 0px 1px 6px #f9f9f9;
    }
    .whyus .card-body {
      font-size:  16px !important;
      font-weight: 500;
    }
    .whyus .card-body h3 {
      font-weight: 600;
      margin-top: 25px
    }
  
    nav {
      position: fixed !important;
      width: 100%  !important;
      z-index: 1  !important;
    }
    .Calculator .card {
      padding: 0px;
    }
    .Calculator .card-footer {
      padding: 30px;
    }
    .sd-shadow {
      box-shadow: 0px 2px 6px #ccc !important;
    }
    #typeit {
      display: inline;
      position: relative;
      color: #F0B884 !important;
    }
    .page-header .page-header-title {
      color: #107F61 !important
    }
    
    .hero-card {
      background: #DBE2EA;
      padding-top: 30px;
      border-radius: 10px;
    }
    .mini-feature {
      margin-top: 40px;
    }
    .mini-feature span {
      font-weight: 600;
    }
    .page-header-content .mini-feature i {
      float: left;
    font-size: 30px;
    margin-right: 10px;
    }
   
    .hero-card button {
      padding: 12px 0px;
    }
    .label-text {
      font-weight: 500;
      font-size: 16px;
    }
    
    .whycoho h1 {
      font-size: 40px;
      color: #3c6b75;
    }
    .whycoho p {
      font-size: 22px;
      color: #777;
    }
    .class-to-action {
      margin: auto;
      background: #DBE2EA;
    }
    .avatar {
      width: 30px;
      padding: 20px;
      border-radius: 100%;
      font-size: 22px;
      color: #fff;
      font-weight: bold;
    }
    .features {
      background: #F7F9FC;
    }
    .clients li {
      display: inline;
      margin: 30px;
      position: relative;
    }
    footer li {
      font-size: 14px;
      font-weight: 500;
      margin-top: 8px;
    }
    footer ul h4 {
      font-size: 16px;
      font-weight: 600;
    }
    .login {
      height: 100%;
    }
    .carousel {
      margin: 0 auto;
      padding-bottom: 50px;
    }
    .carousel .item {
      color: #999;
      font-size: 14px;
        text-align: center;
      overflow: hidden;
        min-height: 340px;
    }
    .carousel .item a {
      color: #eb7245;
    }
    .carousel .img-box {
      width: 145px;
      height: 145px;
      margin: 0 auto;
      border-radius: 50%;
    }
    .carousel .img-box img {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 50%;
    }
    .carousel .testimonial {  
      padding: 30px 0 10px;
    }
    .carousel .overview { 
      text-align: center;
      padding-bottom: 5px;
    }
    .carousel .overview b {
      color: #333;
      font-size: 15px;
      text-transform: uppercase;
      display: block; 
      padding-bottom: 5px;
    }
    .carousel .star-rating i {
      font-size: 18px;
      color: #ffdc12;
    }
    .carousel .carousel-control {
      width: 30px;
      height: 30px;
      border-radius: 50%;
        background: #999;
        text-shadow: none;
      top: 4px;
    }
    .carousel-control i {
      font-size: 20px;
      margin-right: 2px;
    }
    .carousel-control.left {
      left: auto;
      right: 40px;
    }
    .carousel-control.right i {
      margin-right: -2px;
    }
    .carousel .carousel-indicators {
      bottom: 15px;
    }
    .carousel-indicators li, .carousel-indicators li.active {
      width: 11px;
      height: 11px;
      margin: 1px 5px;
      border-radius: 50%;
    }
    .carousel-indicators li { 
      background: #e2e2e2;
      border-color: transparent;
    }
    .carousel-indicators li.active {
      border: none;
      background: #1A82F7;   
    }
    .carousel img {
      margin:  auto;
    }
    .rating img {
      width: 20px;
      display: inline;
    }
    
    .phone-input span {
     font-size: 16px;
     font-weight: 500;
    }
    .call-to-action {
      width: 500px;
      box-shadow: 0px 2px 5px #ddd;
      border-radius: 10px;
      height: 70px;
      background: #fff;
    }
    .call-to-action-input {
      width: 70%;
      padding: 20px;
      font-weight: 600;
      font-size: 18px;
      border-radius: 10px;
      border: 0px;
    }
    .call-to-action .btn {
      padding: 13px 31px !important;
      border: 0px  !important;
      border-radius: 10px  !important;
      box-shadow: -1px 2px 3px #ccc !important;
      font-weight: 500  !important;
    }
    .call-to-action-input:focus {
      outline: 0px;
    }
  
    .bg-hero {
      width: 100%;
      height: 100%;
      position: absolute;
  
    }
    .nav-item a {
      font-weight: 500;
    }
    .card-header img {
      display: block;
    }
  
    /* Pricing page css*/
  
  
  .hero {
      padding-top: 180px;
      padding-bottom: 100px
  }
  .top-nav {
    position: fixed;
    top: 0px;
    z-index: 100;
  }
  
  .faq-item {
    padding: 10px 0px;
  }
  .faq-item span {  
    padding: 10px 0px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
  }
  .faq-item p {
    font-family: 16px;
    font-weight: normal;
  }
  .faqs .faq-item {
    cursor: pointer;
    margin-bottom: 20px;
  }

  .mini-bar {
    z-index: 1001;
    width: 100%;
    position: fixed;
    background: white;
    font-weight: 500;
    padding: 10px;
    top: 0px;
    box-shadow:  0px 1px 5px #ddd;
  }
  .mini-bar-scroll {
    box-shadow: none !important;
  }
  footer .footer-label {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 8px;
  }
  footer h1 {
    font-size: 18px;
    font-weight: 600;
  }
  .navbar .show {
    position: fixed;
    align-items: center;
    background: #fff;
    margin-top: 336px;
    width: 100%;
    left: 0;
    padding: 24px;
    box-shadow: -8px 0px 4px #ddd;
}
.page-header .page-header-text {
  display: none;
}
.my-form {
  height: 76vh;
  margin-top: 60px;
  margin-bottom: 36px;
}

.navbar-light .navbar-nav .nav-link {
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #333 !important;
}
