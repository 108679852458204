body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.connect-card {
  height: 200px;
  background: #1D976C;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #93F9B9, #1D976C);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #93F9B9, #1D976C);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.profile-photo {
    width: 174px;
      height: 180px;
  margin-top: 100px;
  border: 3px solid #fff;
  border-radius: 100%;
}
.social {
  margin: auto !important;
}
.social li {
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
  padding: 0;
}
.social li img { 
    box-shadow: 2px 2px 6px #ccc;
    border-radius: 14px;
    margin-bottom: 5px;
}