@media (min-width: 992px) {

  .Calculator {
    margin-top: 130px;
  }
  .nav-buttons {
      position: absolute;
      right: 10%;
      float: right;
  }
  .family-img {
    width: 400px;
  }
  .content-header-text {
    margin-top: -177px !important;
   
  }
  .whyus {
    flex-wrap: nowrap !important;
  }
  header {
    padding-top: 10em !important; 
  }
  .page-header-title {
    font-size: 60px !important;
    font-weight: 700 !important;
    line-height: 85px;
  }
  .page-header-text {
    font-size: 16px!important;
    font-weight: 400;
    margin-top: 10px!important;
  }
  .page-header {
    padding-top: 7.5rem !important;
    padding-bottom: 7em !important;
   
  }
  .page-header .card {
    width: 400px !important;
    float: right !important;
  }
  .bg-hero {
      width: 100%;
      height: 100%;
      position: absolute;
  
    }
    .bg-hero-3 {
  
    }
    .bg-hero-2 {
      background-image: url(../img/images/hero-bg-2.png);
      background-size: 250px;
      background-repeat: no-repeat;
      background-position: -130px 0px;
    }
    .bg-hero-1 {
   background-image: url(../img/images/hero-bg.png);
    background-size: 640px;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: 19%;
  
    }
    .features img {
      width: 520px;
      margin-top: -100px !important;
    }

  header .page-header-section {
    margin-left: 150px !important;

  }
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 574px) {

  header {
      height: 90vh !important;
  }
  .floating-button {
    position: fixed;
    bottom: 0px;
    width: 100vw !important;
    padding: 20px;
    border-top: 1px solid #ccc;
    background: white;
    z-index: 10000;
    display: block;
  }
  .features img {
      width: 320px!important;
  }
  .page-header  {
    background: #017849 !important;
  }
  .page-header .page-header-title {
    color: #fff  !important;
  }
  
  .family-img {
    width: 300px !important;
  }
  .call-to-action-input {
    width: 65%;
  }
  .Calculator .footer-result {
    position: fixed;
    bottom: 0 !important;
    z-index: 200;
    border: 1px solid #ccc;
        padding: 20px 0px;
    background: #fff;
    width: 100%;
    margin: 0px;
    display: flex;
  }
  .hero {
    padding-top: 120px !important
  }
  .footer-result .save-text {
    text-decoration-style: dashed !important;

  }
  .Calculator .card {
    display: none;
  }
header .page-header-title {
    font-size: 36px !important;
    font-weight: 700 !important;
}
.page-header-title {
font-size: 42px !important;
font-weight: 700 !important;

}
.navbar .container {
    padding: 0px 20px;
}
.navbar-marketing {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.mini-bar  {
  box-shadow: none;
}
.navbar-main .page-header-text {
  font-size: 16px !important;
  color: #fff !important;
  display: block;
}
.navbar-main .navbar-brand, .navbar-main svg {
  color: #fff !important;
}
.scrolling-nav .navbar-brand, .scrolling-nav svg {
  color: #000 !important;
}
.call-to-action {
  box-shadow: none !important;
}
.mini-feature, .mini-bar i {
  color: #fff !important;
}
.mini-feature span {
  color: rgba(255,255,255,.7)!important
}
.mini-feature i {
  color: rgba(255,255,255,.7)!important
}
.call-to-action {
  width: 100%;
}
.call-to-action .btn {
  padding: 12px 17px !important;
}

.bg-hero {
  position: absolute;
bottom: 0%;
}
.bg-hero-3 {

}
.mini-bar .callus {
  display: none;
}
.bg-hero-1 {
display: none;

}

} 